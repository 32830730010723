import { createSlice } from "@reduxjs/toolkit";

interface ScoreHelpState {
    currentTitle: string;
}

const initialState: ScoreHelpState = {
    currentTitle: ''
}

export const scoreHelpSlice = createSlice({
    name: 'score-help',
    initialState,
    reducers: {
        closeOtherHelps: (state, action) => {
            return { ...state, currentTitle: action.payload }
        }
    }
})

export const { closeOtherHelps } = scoreHelpSlice.actions