import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DropdownSelection, readDropdown } from '../../../store/slices/dropdown';
import { RangeFilter } from '../RangeFilter/RangeFilter';
import {
  Button,
  Checkbox,
  FormControl,
  Icon,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { MenuProps } from './dropdown-styles';
import { DropdownItem } from '../../../models/dropdown';
import { FILTER_FIELD, resetFilters, updateFilters } from '../../../store/slices/filter';
import { Link } from 'react-router-dom';
import { RoutePath } from '../../../constants/router';
import { YEAR_RANGES } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';
import styles from './AssesseeSearch.module.css';
import { useGetSettingsQuery } from '../../../store/slices/apiSlice';
import _ from 'lodash';

const icons = {
  [DropdownSelection.COUNTRY]: 'public',
  [DropdownSelection.SECTOR]: 'agriculture',
  [DropdownSelection.LEGAL_STATUS]: 'policy',
};

type DropdownData = {
  [key in DropdownSelection]: string[];
};

type DropdownItemData = {
  [key in DropdownSelection]: DropdownItem[];
};

export const AssesseeSearch = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('AssesseeSearch');
  const { data: settings } = useGetSettingsQuery();
  let infoTexts = {};

  const filters = useAppSelector((state) => state.filters);

  const financialOverview = {
    total_revenue: 'Total Revenue',
    net_profit: 'Net Profit',
  };

  const dropdowns: DropdownItemData = {
    [DropdownSelection.COUNTRY]: useAppSelector((state) => state.dropdowns.country),
    [DropdownSelection.SECTOR]: useAppSelector((state) => state.dropdowns.sector),
    [DropdownSelection.LEGAL_STATUS]: useAppSelector((state) => state.dropdowns.legal_status),
  };

  const dropdownSelections: DropdownData = {
    [DropdownSelection.COUNTRY]: useAppSelector((state) => state.filters.country),
    [DropdownSelection.SECTOR]: useAppSelector((state) => state.filters.sector),
    [DropdownSelection.LEGAL_STATUS]: useAppSelector((state) => state.filters.legal_status),
  };

  const handleDropdownChange = (type: FILTER_FIELD, event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;
    dispatch(
      updateFilters({ field: type, value: typeof value === 'string' ? value.split(',') : value })
    );
  };

  const generateSearchQuery = () => {
    const queries = [];
    for (const key in dropdownSelections) {
      if (dropdownSelections[key].length) {
        queries.push(
          `${key}=${dropdownSelections[key]
            .map((item) => JSON.parse(item)[key === DropdownSelection.COUNTRY ? 'name' : 'id'])
            .join(`&${key}=`)}`
        );
      }
    }
    for (const key in filters) {
      if (!(key in dropdownSelections)) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== '') {
          queries.push(`${key}=${filters[key]}`);
        }
      }
    }
    return queries.join('&');
  };

  useEffect(() => {
    dispatch(readDropdown(DropdownSelection.COUNTRY));
    dispatch(readDropdown(DropdownSelection.SECTOR));
    dispatch(readDropdown(DropdownSelection.LEGAL_STATUS));
  }, []);

  useEffect(() => {
    // const { disclaimerText, disclaimerFont, ...infoTexts } = settings;
    infoTexts = _.pick(settings, 'infos');
  }, [settings]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>{t('Start the search for your desired match')}</div>
      <div className={styles.searchBar}>
        <OutlinedInput
          className={styles.financialInput}
          placeholder={t('Search by name...')}
          value={filters['name']}
          onChange={(e) =>
            dispatch(updateFilters({ field: 'name', value: e.target.value || ('' as any) }))
          }
        />
      </div>

      <div className={styles.main}>
        <div className={styles.sectionContainer}>
          {[
            DropdownSelection.COUNTRY,
            DropdownSelection.SECTOR,
            DropdownSelection.LEGAL_STATUS,
          ].map((selection) => (
            <div key={selection} className={styles.rowSegmentContainer}>
              <div></div>
              <FormControl
                // sx={{ m: 1, width: 300, alignItems: 'center' }}
                className={styles.formController}
              >
                <Select
                  value={dropdownSelections[selection]}
                  onChange={(e) => handleDropdownChange(selection, e)}
                  input={
                    <OutlinedInput
                      startAdornment={
                        <>
                          <Icon style={{ marginRight: '1em' }}>{icons[selection]}</Icon>
                          {!dropdownSelections[selection].length && (
                            <span style={{ width: '100%' }}>
                              {selection
                                .split('_')
                                .map((item) => item[0].toUpperCase() + item.substring(1))
                                .join(' ')}
                            </span>
                          )}
                        </>
                      }
                    />
                  }
                  className={styles.financialInput}
                  multiple
                  MenuProps={MenuProps}
                  renderValue={(selected) =>
                    selected.map((item) => JSON.parse(item)['display_name']).join(', ')
                  }
                >
                  {(dropdowns[selection] as DropdownItem[]).map((item) => (
                    <MenuItem key={item.id} value={JSON.stringify(item)}>
                      <Checkbox
                        checked={
                          (dropdownSelections[selection] as string[]).indexOf(
                            JSON.stringify(item)
                          ) > -1
                        }
                      />
                      <ListItemText primary={item.display_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
        </div>

        {settings && settings.infos && (
          <div className={styles.sectionContainer}>
            {Object.keys(settings?.infos).map((item) => {
              let temp = item.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
              temp = temp.replace('info', 'score');
              return (
                <RangeFilter
                  key={item}
                  itemTitle={temp}
                  title={item as any}
                  data={settings.infos[item]}
                ></RangeFilter>
              );
            })}
          </div>
        )}

        <div className={styles.sectionContainer}>
          <div className={styles.rowSegmentContainer}>
            <div>Years in Operation</div>
            <FormControl
              // sx={{ m: 1, width: 300, alignItems: 'center' }}
              className={styles.formController}
            >
              <Select
                value={filters['years_in_operation']}
                onChange={(e) => {
                  console.log(e.target.value);
                  dispatch(
                    updateFilters({
                      field: 'years_in_operation',
                      value: +e.target.value,
                    })
                  );
                }}
                input={
                  <OutlinedInput
                    startAdornment={
                      filters['years_in_operation'].length == 0 && (
                        <span style={{ width: '100px', userSelect: 'none', cursor: 'pointer' }}>
                          Select
                        </span>
                      )
                    }
                  />
                }
                className={styles.financialInput}
              >
                {YEAR_RANGES.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    <ListItemText primary={item.displayName} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {Object.keys(financialOverview).map((input) => (
            <div key={input} className={styles.rowSegmentContainer}>
              <div>{financialOverview[input]}</div>
              <div>
                <FormControl
                  // sx={{ m: 1, width: 300, alignItems: 'center' }}
                  className={styles.formController}
                >
                  <OutlinedInput
                    value={filters[input]}
                    onChange={(e) => {
                      dispatch(
                        updateFilters({
                          field: input as FILTER_FIELD,
                          value: +e.target.value || ('' as any),
                        })
                      );
                    }}
                    placeholder="$ MIN"
                    className={styles.financialInput}
                  />
                </FormControl>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.submit}>
        <Link
          className={styles.filterLink}
          to={`/${RoutePath.ASSESSEES_TABLE}?${generateSearchQuery()}`}
        >
          <Button className={styles.filterButton} variant="contained" endIcon={<Icon>search</Icon>}>
            {t('Search')}
          </Button>
        </Link>
        <a href="#" className={styles.resetFiltersLink} onClick={() => dispatch(resetFilters())}>
          {t('Reset Filters')}
        </a>
      </div>
    </div>
  );
};
