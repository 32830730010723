import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AUTH_KEY, BASE_API_URL } from '../../constants/constants';
import { AdminSettings } from '../../models/admin';
import { Assessee, AssesseeParams } from '../../models/assessee';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(AUTH_KEY);
      if (token) {
        headers.set('Authorization', `JWT ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<AdminSettings, void>({
      query: () => '/hrm/settings/',
      transformResponse: (rawResult: {
        portal_disclaimer_text: string;
        portal_disclaimer_font_size: number;
        portal_info_text_total_score: string;
        portal_info_text_market_score: string;
        portal_info_text_financial_score: string;
      }) => {
        return {
          disclaimerText: rawResult.portal_disclaimer_text,
          disclaimerFont: rawResult.portal_disclaimer_font_size,
          infos: {
            totalInfo: rawResult.portal_info_text_total_score,
            marketInfo: rawResult.portal_info_text_market_score,
            financialInfo: rawResult.portal_info_text_financial_score,
          },
        };
      },
    }),
    getAssessees: builder.query<Assessee, AssesseeParams>({
      query: (assesseeParams: AssesseeParams) => '/customers/producing_organizations',
    }),
  }),
});

export const { useGetSettingsQuery } = apiSlice;
