import { Button, MenuItem, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCustomers, impersonateUser } from '../../../store/slices/auth';
import styles from './ImpersonateModal.module.css';
import GridLoader from 'react-spinners/GridLoader';
import { AUTH_KEY } from '../../../constants/constants';

Modal.setAppElement('#root');
export const ImpersonateModal = (props: { isOpen; closeModal }) => {
  const dispatch = useAppDispatch();
  const customers = useAppSelector((state) => state.auth.customers);
  const fetching = useAppSelector((state) => state.auth.fetching);

  const [selectedClient, setSelectedClient] = useState<any>('');
  const [selectedUser, setSelectedUser] = useState<any>('');

  useEffect(() => {
    dispatch(getCustomers());
  }, []);

  useEffect(() => {
    if (fetching) {
      setSelectedClient('');
      setSelectedUser('');
    }
  });

  const handleClientSelect = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleUserSelect = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleCancel = () => {
    props.closeModal();
    setSelectedClient('');
    setSelectedUser('');
  };

  const handleImpersonate = () => {
    localStorage.setItem('old_token', localStorage.getItem(AUTH_KEY));
    dispatch(impersonateUser(selectedUser));
  };

  return (
    <Modal
      isOpen={props.isOpen}
      // onAfterOpen={props.afterOpenModal}
      onRequestClose={props.closeModal}
      style={styles.modal}
      contentLabel="Impersonate User"
      className={styles.modalWrapper}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <h3>Impersonate User</h3>
      </div>
      {fetching ? (
        <div className={styles.loaderContainer}>
          Fetching Clients
          <GridLoader size={150} />
        </div>
      ) : (
        <div className={styles.inputContainer}>
          <span>Select a Client</span>
          {customers.length > 0 && (
            <TextField
              label="Select a Client"
              select
              defaultValue=""
              value={selectedClient}
              onChange={(e) => handleClientSelect(e)}
              className={styles.dropdown}
              SelectProps={{ className: styles.dropdownInput }}
            >
              {customers.map((x: any) => {
                return (
                  <MenuItem value={x} key={x.id}>
                    {x.name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
        </div>
      )}
      {!fetching && selectedClient && (
        <div className={styles.inputContainer}>
          <span>Select a User</span>
          <TextField
            label="Select a User"
            select
            defaultValue=""
            value={selectedUser}
            onChange={(e) => handleUserSelect(e)}
            className={styles.dropdown}
            SelectProps={{ className: styles.dropdownInput }}
          >
            {selectedClient.users.map((x: any) => {
              return (
                <MenuItem value={x} key={x.id}>
                  {x.first_name + ' ' + x.last_name}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      )}
      <div className={styles.submitButtons}>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={fetching || !selectedUser}
          onClick={handleImpersonate}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
