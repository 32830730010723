import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { requestPasswordReset } from '../../../store/actions/auth';
import { CustomButton } from '../../utils/CustomButton/CustomButton';
import { CustomFormControl } from '../../utils/CustomFormControl/CustomFormControl';
import { IntroPage } from '../../utils/IntroPage/IntroPage';
import { SubmitResponse } from '../../utils/SubmitResponse/SubmitResponse';
import styles from './ResetPasswordForm.module.css'

export const ResetPasswordForm = () => {
  const { t } = useTranslation('ResetPasswordForm');
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>();
  const dispatch = useDispatch();

  const resetPasswordHandler = async () => {
    try {
      await dispatch(requestPasswordReset(email));
      setError(false);
    } catch {
      setError(true);
    }
  };

  return (
    <IntroPage
      form={
        <>
          <div className={styles.title}>
            {t('Please fill in your registered email address to receive a password reset link.')}
          </div>
          <div className={styles.form}>
            <CustomFormControl
              type="text"
              label={t('Email Address')}
              value={email}
              onChange={(e) => setEmail(e)}
            />
            <div className={styles.submit}>
              <CustomButton disabled={!email} onClick={resetPasswordHandler}>
                {t('Reset Password')}
              </CustomButton>
            </div>
          </div>
          {error && (
            <SubmitResponse
              text={t('Password reset Failed. Please enter a valid  email address.')}
              error={true}
            />
          )}
          {error === false && (
            <SubmitResponse
              text={t(
                'Password reset request was sent successfully. Please check your email to reset your password.'
              )}
            />
          )}
        </>
      }
    />
  );
};
