import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AUTH_KEY } from '../../../constants/constants';
import styles from './Toolbar.module.css';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearCurrentUser, me } from '../../../store/slices/auth';
import { ImpersonateModal } from '../ImpersonateModal/ImpersonateModal';
import { useState, useEffect } from 'react';

export const Toolbar = () => {
  const { t } = useTranslation('Toolbar');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const impersonateToken = useAppSelector((state) => state.auth.impersonateToken);
  const currentUser = useAppSelector((state) => state.auth.me);
  const isImpersonating = localStorage.getItem('impersonating');

  const [openModal, setOpenModal] = useState(false);

  const handleLogout = () => {
    dispatch(clearCurrentUser());
    localStorage.clear();
    navigate('/login');
  };

  const handleImpersonate = () => {
    setOpenModal(true);
  };

  const handleImpersonateFinished = () => {
    setOpenModal(false);
  };

  const returnToOriginalUser = () => {
    localStorage.removeItem('impersonating');
    localStorage.setItem(AUTH_KEY, localStorage.getItem('old_token'));
    localStorage.removeItem('old_token');
    dispatch(me());
    window.location.reload();
  };

  useEffect(() => {
    dispatch(me());
  }, []);

  useEffect(() => {
    if (impersonateToken) dispatch(me());
  }, [impersonateToken]);

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarTitle}>
        <div className={styles.title}>{t('SCOPE Portal')}</div>
      </div>
      <div className={styles.actionsContainer}>
        <span className={styles.user}>
          {currentUser?.first_name + ' ' + currentUser?.last_name}
        </span>
        {currentUser?.is_employee && (
          <div className={styles.impersonateContainer}>
            {!isImpersonating ? (
              <VisibilityIcon onClick={handleImpersonate} />
            ) : (
              <VisibilityIcon style={{ color: 'red' }} onClick={returnToOriginalUser} />
            )}
            {openModal && (
              <ImpersonateModal isOpen={openModal} closeModal={handleImpersonateFinished} />
            )}
          </div>
        )}
        <div className={styles.logoutButtonHolder}>
          <Button onClick={handleLogout} variant="outlined" className={styles.logoutButton}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};
