import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { authSlice } from './slices/auth';
import { filterSlice } from './slices/filter';
import { scoreHelpSlice } from './slices/score-help';
import { dropdownSlice } from './slices/dropdown';
import { assesseeSlice } from './slices/assessee';
import { apiSlice } from './slices/apiSlice';

// TODO: remove all old redux files after app is tested
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    scoreHelp: scoreHelpSlice.reducer,
    filters: filterSlice.reducer,
    assessee: assesseeSlice.reducer,
    dropdowns: dropdownSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
