import { OutlinedInput, Button } from '@mui/material';
import styles from './SetPasswordForm.module.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoutePath } from '../../../constants/router';
import { useEffect, useState } from 'react';
import { IntroPage } from '../../utils/IntroPage/IntroPage';
import { CustomFormControl } from '../../utils/CustomFormControl/CustomFormControl';
import { sendPasswordRequest } from '../../../store/slices/auth';
import { SubmitResponse } from '../../utils/SubmitResponse/SubmitResponse';
import { useAppDispatch } from '../../../store/hooks';
import ErrorLabel from '../../utils/ErrorLabel/ErrorLabel';

export const SetPasswordForm = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [validity, setValidity] = useState(false);
  const [redirectNotice, setRedirectNotice] = useState(false);

  useEffect(() => {
    if (!token) {
      navigation(`/${RoutePath.LOGIN}`);
      return null;
    }
  }, []);

  useEffect(() => {
    checkValidity();
  }, [password, confirmPassword]);

  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('SetPasswordForm');

  const token = searchParams.get('token');

  const handlePasswordSetting = () => {
    if (password) {
      if (validity && password === confirmPassword) {
        dispatch(sendPasswordRequest({ token: token, password: password }))
          .then(() => setRedirectNotice(true))
          .catch((error) => {
            // Case if the back-end rejects the reset passowrd request
            displayError(error);
          });
      } else {
        // Case if the passwords do not match
        displayError('Error, passwords must match');
      }
    }
  };

  const displayError = (errorText) => {
    setError(true);
    setErrorText(errorText);
  };

  const checkValidity = () => {
    const testNumericAndCharacters = /(\d)|(@|#|\$)/g;
    const testLowerCase = /[a-z]+/g;
    const testUpperCase = /[A-Z]+/g;
    setValidity(
      testNumericAndCharacters.test(password) &&
        testLowerCase.test(password) &&
        testUpperCase.test(password) &&
        password.length >= 8
    );
  };

  return (
    <IntroPage
      form={
        <div className={styles.container}>
          <div className={styles.form}>
            <CustomFormControl
              label={t('Enter Password...')}
              type="password"
              value={password}
              onChange={(e) => setPassword(e)}
            />
          </div>
          <div className={styles.form}>
            <CustomFormControl
              label={t('Confirm Password...')}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e)}
            />
          </div>
          <div className={styles.bottomContainer}>
            <div className={styles.submit}>
              <Button
                onClick={handlePasswordSetting}
                className={styles.filterButton}
                variant="contained"
                disabled={!validity}
              >
                {t('Set Password')}
              </Button>
            </div>
            <div className={validity ? styles.reminderTextValid : styles.reminderText}>
              {t('Please make sure the password is:')}
              <ul>
                <li>{t('At least 8 characters long')}</li>
                <li>{t('Has at least one upper case character')}</li>
                <li>{t('Has at least one numerical character')}</li>
              </ul>
            </div>
            {error && <SubmitResponse error={error} text={errorText} />}
            {redirectNotice && (
              <SubmitResponse
                text={'Password successfully set, redirecting to login page shortly'}
              />
            )}
          </div>
        </div>
      }
    />
  );
};
