import {
  Button,
  InputLabel,
  Pagination,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { DataGrid, GridColDef, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SIDEBAR_WIDTH, TOOLBAR_HEIGHT, PAGE_SIZE_TABLE } from '../../../constants/constants';
import { searchAssessees, exportToExcel, exportToPdf } from '../../../store/slices/assessee';
import { Assessee } from '../../../models/assessee';
// import { ProfileCard } from '../utils/ProfileCard';
import { RoutePath } from '../../../constants/router';
import { resetFilters } from '../../../store/slices/filter';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import GridLoader from 'react-spinners/GridLoader';
import styles from './AssesseeTable.module.css';
import { OutlinedInput } from '@material-ui/core';

export const AssesseeTable = () => {
  const { t } = useTranslation('AssesseeTable');
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const pagesCount = useAppSelector((state) => state.assessee.pagesCount);
  const assessees = useAppSelector((state) => state.assessee.assessees);
  const downloading = useAppSelector((state) => state.assessee.downloading);
  const loading = useAppSelector((state) => state.assessee.loading);
  const dataCount = useAppSelector((state) => state.assessee.dataCount);

  const navigate = useNavigate();
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);

  const columns: GridColDef[] = [
    {
      field: 'company_name',
      headerName: t('Company Name'),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.customer.name,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCellLarge,
      // // headerAlign: 'center',
      // // align: 'center',
    },
    {
      field: 'country',
      headerName: t('Country'),
      flex: 1,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
    },
    {
      field: 'sector',
      headerName: t('Sector'),
      flex: 1,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCellLarge,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'year_of_incorporation',
      headerName: t('Year Founded'),
      flex: 1,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'total_revenue',
      headerName: t('Total Revenue'),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.total_revenue && `$${(+params.row.total_revenue).toLocaleString('en-US')}`,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'total_score',
      headerName: t('SCOPE Score'),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return (
          params.row.total_score &&
          `${(+params.row.total_score).toFixed(1)} (${params.row.last_assessment_type
            .replace('SCOPE', '')
            .trim()})`
        );
      },
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'net_profit',
      headerName: t('Net Profit'),
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        params.row.net_profit && `$${(+params.row.net_profit).toLocaleString('en-US')}`,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'legal_status',
      headerName: t('Legal Status'),
      flex: 1,
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCellLarge,
      // headerAlign: 'center',
      // align: 'center',
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <div
          className={styles.downloadButton}
          onClick={() =>
            dispatch(exportToPdf({ search: location.search, selectedIds: [params.id] }))
          }
        >
          Download
        </div>
      ),
      headerClassName: styles.tableHeader,
      cellClassName: styles.tableCell,
      // headerAlign: 'center',
      // align: 'center',
    },
  ];

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [view, setView] = useState('list');
  const [currentCountPerPage, setCurrentCountPerPage] = useState<number>(PAGE_SIZE_TABLE[0]);

  useEffect(() => {
    dispatch(
      searchAssessees({
        search: location.search.replace('?', ''),
        page: pageNumber,
        viewType: view,
        pageSize: currentCountPerPage,
      })
    );
  }, [pageNumber, location, view, currentCountPerPage]);

  const handleToggleChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      //when changing view type, reset the page to the first one to avoid errors when looking at later pages in card mode
      setPageNumber(1);
      setView(newView);
    }
  };

  const handleFilterChange = () => {
    dispatch(resetFilters());
    navigate(`/${RoutePath.ASSESSEES_SEARCH}`);
  };

  const handlePageCountChange = (event) => {
    setCurrentCountPerPage(event.target.value);
  };

  const handleDownloadClick = () => {
    downloadPdfs();
  };

  // const downloadExcel = () => {
  //   dispatch(exportToExcel({ search: location.search, selectedIds: selectedIds }));
  // };

  const downloadPdfs = () => {
    dispatch(exportToPdf({ search: location.search, selectedIds: selectedIds }));
  };

  // const createCardList = (assessees: Assessee[]) => {
  //   const assesseeCards = assessees.map((assessee) => {
  //     return (
  //       <ProfileCard key={assessee.id} assessee={assessee} />
  //     )
  //   })
  //   return assesseeCards;
  // }

  // const assesseeCards = createCardList(assessees);

  if (!assessees?.length && !loading) {
    return <div className={styles.container}>No Assessees found, please try different filters</div>;
  }

  return (
    <div className={styles.container}>
      {loading && (
        <div className={styles.loaderContainer}>
          <GridLoader size={50} color="#52c7e0"></GridLoader>
        </div>
      )}
      <DataGrid
        disableSelectionOnClick
        autoHeight
        rows={assessees}
        columns={columns}
        checkboxSelection
        hideFooter={true}
        onSelectionModelChange={(newSelectedIds) => {
          setSelectedIds(newSelectedIds);
        }}
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, minWidth: '1080px' }}
        className={styles.dataTable}
      />
      <div className={styles.lowerBarContainer}>
        <div className={styles.countContainer}>Number of Results: {dataCount}</div>
        <div className={styles.paginationAndExport}>
          {downloading ? (
            <div className={styles.downloadPending}>
              <GridLoader></GridLoader>
              Please wait while we prepare Your download...
            </div>
          ) : (
            <Button onClick={() => handleDownloadClick()} color="success" variant="contained">
              {selectedIds.length ? 'Download Selected' : 'Download All'}
            </Button>
          )}

          <div style={{ display: 'flex' }}>
            <TextField
              label="Count per Page"
              select
              value={currentCountPerPage}
              onChange={(e) => handlePageCountChange(e)}
              className={styles.dropdown}
              SelectProps={{ className: styles.dropdownInput }}
            >
              {PAGE_SIZE_TABLE.map((x) => {
                return (
                  <MenuItem value={x} key={x}>
                    {x}
                  </MenuItem>
                );
              })}
            </TextField>

            <Pagination
              className={styles.paginator}
              count={pagesCount}
              onChange={(_, page) => setPageNumber(page)}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </div>
  );
};
