import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from '../../models/auth';
import { Login } from '../../models/login';
import { AUTH_KEY } from '../../constants/constants';
import axios from 'axios';
import type { RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../constants/router';

interface AuthState {
  auth: Auth;
  customers: [];
  fetching: boolean;
  impersonateToken: string;
  me: any;
  settings: any;
}

const initialState: AuthState = {
  auth: null,
  customers: [],
  fetching: false,
  impersonateToken: '',
  me: null,
  settings: null,
};

export const loginUser = createAsyncThunk('auth/loginUser', async (login: Login, thunkAPI) => {
  try {
    const auth = (await axios.post(`${process.env.REACT_APP_API_URL}/api-token-auth/`, login)).data;
    return auth;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error.response.data?.non_field_errors[0] || 'An unexpected error has occurred'
    );
  }
});

export const me = createAsyncThunk('auth/me', async (thunkAPI) => {
  const me = (await axios.get(`${process.env.REACT_APP_API_URL}/accounts/users/me/`)).data;
  return me;
});

export const getCustomers = createAsyncThunk('auth/getCustomers', async (thunkAPI) => {
  const impersonateCustomers = (
    await axios.get(`${process.env.REACT_APP_API_URL}/customers/customers/`, {
      params: { fields: 'users', page_size: 99999 },
    })
  ).data;
  return impersonateCustomers;
});

export const impersonateUser = createAsyncThunk('auth/impersonate', async (user: any, thunkAPI) => {
  const auth = (
    await axios.post(`${process.env.REACT_APP_API_URL}/accounts/users/impersonate/`, user)
  ).data;
  return auth;
});

export const sendPasswordRequest = createAsyncThunk(
  'auth/sendPasswordRequest',
  async (params: { token: string; password: string }, thunkAPI) => {
    try {
      const auth = (
        await axios.post(
          `${process.env.REACT_APP_API_URL}/accounts/users/apply_password_reset/`,
          params
        )
      ).data;
      return auth;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response.data?.token[0] || 'An unexpected error has occurred'
      );
    }
  }
);

export const getSettings = createAsyncThunk('auth/getSettings', async (thunkAPI) => {
  const settings = (await axios.get(`${process.env.REACT_APP_API_URL}/hrm/settings/`)).data;
  return settings;
});

// export const sendPasswordRequest = createAsyncThunk(
//   'auth/sendPasswordRequest',
//   async (params: { token: string; password: string }, thunkAPI) => {
//     console.log('sending request');
//     const request = (
//       await axios.post(
//         `${process.env.REACT_APP_API_URL}/accounts/users/apply_password_reset/`,
//         params
//       )
//     ).data;
//     console.log('sent request request');

//     return request;
//   }
// );

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearCurrentUser: (state) => {
      console.log('cleared current user');
      return { ...state, me: initialState.me };
    },
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(loginUser.pending, (state, action) => {
      console.log('fetching login status');
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      localStorage.setItem(AUTH_KEY, action.payload.token);
      console.log('successfully logged in', action.payload);
      return action.payload;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      console.log('login failed', action.payload);
      throw action.payload;
    });

    // get all impersonateable customers
    builder.addCase(getCustomers.pending, (state, action) => {
      console.log('fetching impersonate customers');
      return { ...state, fetching: true };
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      console.log('successfully gotten impersonate customers', action.payload);
      return { ...state, customers: action.payload.results, fetching: false };
    });
    builder.addCase(getCustomers.rejected, (state, action) => {
      console.log('failed to fetch impersonate customers', action.error);
      throw { ...state, fetching: false };
    });

    // impersonate user
    builder.addCase(impersonateUser.pending, (state, action) => {
      console.log('impersonating');
      return { ...state, fetching: true };
    });
    builder.addCase(impersonateUser.fulfilled, (state, action) => {
      console.log('successfully impersonated', action.payload);
      localStorage.setItem(AUTH_KEY, action.payload.token);
      localStorage.setItem('impersonating', 'true');
      window.location.reload();
      return { ...state, impersonateToken: action.payload, fetching: false };
    });
    builder.addCase(impersonateUser.rejected, (state, action) => {
      console.log('failed to impersonate', action.error);
      throw { ...state, fetching: false };
    });

    // get current user
    builder.addCase(me.fulfilled, (state, action) => {
      console.log('successfully got me', action.payload);
      return { ...state, me: action.payload };
    });
    builder.addCase(me.rejected, (state, action) => {
      console.log('failed to get me', action.error);
      throw { ...state };
    });

    // get global settings object
    builder.addCase(getSettings.fulfilled, (state, action) => {
      console.log('successfully got settings', action.payload);
      return { ...state, settings: action.payload };
    });
    builder.addCase(getSettings.rejected, (state, action) => {
      console.log('failed to get settings', action.error);
      throw { ...state };
    });

    // send password reset request
    builder.addCase(sendPasswordRequest.pending, (state, action) => {
      console.log('setting password');
    });
    builder.addCase(sendPasswordRequest.fulfilled, (state, action) => {
      console.log('successfully set Password');
      setTimeout(() => {
        window.location.href = `/${RoutePath.LOGIN}`;
      }, 5000);
    });
    builder.addCase(sendPasswordRequest.rejected, (state, action) => {
      console.log('failed to set Password', action.payload);
      throw action.payload;
    });
  },
});

export const { clearCurrentUser } = authSlice.actions;
