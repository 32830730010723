export const SIDEBAR_WIDTH = '14em';
export const SIDEBAR_COLOR = '#282561';
export const TOOLBAR_HEIGHT = '3em';
export const TOOLBAR_COLOR = '#52c7e0';
export const TOOLBAR_HINT_COLOR = '#282561';
export const AUTH_KEY = 'auth';
export const MD_HIGH = 1200;
export const PAGE_SIZE_CARD = 6;
export const PAGE_SIZE_TABLE = [10, 25, 50, 100];
export const PAGE_SIZE_INF = 9999;
export const TOOL_TYPES = [
  'SCOPE Basic',
  'SCOPE Pro',
  'SCOPE Basic SME',
  'SCOPE Pro SME',
  'SCOPE Input Retailer',
];
export const YEAR_RANGES = [
  { displayName: '0 - 5', value: 0 },
  { displayName: '5 - 10', value: 1 },
  { displayName: '10 - 15', value: 2 },
  { displayName: '15+', value: 3 },
];
export const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
export const EXCLUDED_URLS = [
  `${BASE_API_URL}/accounts/users/request_password_reset/`,
  `${BASE_API_URL}/accounts/users/apply_password_reset/`,
];
