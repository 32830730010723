import { Button } from '@mui/material';
import styles from './CustomButton.module.css'

export const CustomButton = (props: {
  children: JSX.Element | string;
  disabled: boolean;
  onClick: () => void;
}) => {
  return (
    <Button variant="contained" className={styles.button} disabled={props.disabled} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};
