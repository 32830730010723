import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormControl, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { useState } from 'react';
import styles from './CustomFormControl.module.css'

export const CustomFormControl = (props: {
  type: 'text' | 'password';
  label: string;
  value: string;
  onChange: (value: string) => void;
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <FormControl className={styles.input}>
      <OutlinedInput
        placeholder={props.label}
        size="small"
        endAdornment={
          props.type === 'password' && (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword((prev) => !prev)}
                onMouseDown={() => setShowPassword((prev) => !prev)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }
        type={props.type === 'password' && showPassword ? 'text' : props.type}
        style={{ borderRadius: '2em' }}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </FormControl>
  );
};
