import styles from './SubmitResponse.module.css';

export const SubmitResponse = (props: { text: string; error?: boolean }) => {
  return (
    <div className={[styles.container, props.error ? styles.containerError : ''].join(' ')}>
      {props.error && <img className={styles.error} src="images/login_error.png" />}
      <span>{props.text}</span>
    </div>
  );
};
