import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DropdownItem } from "../../models/dropdown";
import { PAGE_SIZE_INF } from "../../constants/constants";

//TODO: move to constants file
export enum DropdownSelection {
    COUNTRY = 'country',
    LEGAL_STATUS = 'legal_status',
    SECTOR = 'sector'
}

const DROPDOWN_URLS = {
    [DropdownSelection.COUNTRY]: `${process.env.REACT_APP_API_URL}/products/country_options/?page_size=${PAGE_SIZE_INF}`,
    [DropdownSelection.LEGAL_STATUS]: `${process.env.REACT_APP_API_URL}/products/legal_status_options/?page_size=${PAGE_SIZE_INF}`,
    [DropdownSelection.SECTOR]: `${process.env.REACT_APP_API_URL}/customers/sectors/?page_size=${PAGE_SIZE_INF}`,
}

interface DropdownState {
    [DropdownSelection.COUNTRY]: DropdownItem[]
    [DropdownSelection.LEGAL_STATUS]: DropdownItem[],
    [DropdownSelection.SECTOR]: DropdownItem[],
}

const initialState: DropdownState = {
    [DropdownSelection.COUNTRY]: [],
    [DropdownSelection.LEGAL_STATUS]: [],
    [DropdownSelection.SECTOR]: [],
}

export const readDropdown = createAsyncThunk(
    'dropdown/readDropdown',
    async (selection: DropdownSelection) => {
        const dropdown = (await axios.get(DROPDOWN_URLS[selection])).data.results as DropdownItem[]
        return { dropdown, selection }
    }
)

export const dropdownSlice = createSlice({
    name: 'dropdown',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(readDropdown.pending, (state, action) => {
            console.log('fetching dropdown data');
        })
        builder.addCase(readDropdown.fulfilled, (state, action) => {
            console.log('successfully fetched dropdowns', action.payload);
            return { ...state, [action.payload.selection]: action.payload.dropdown }
        })
    }
})