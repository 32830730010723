import styles from './Footer.module.css';
import { useGetSettingsQuery } from '../../../store/slices/apiSlice';

export const Footer = () => {
  const { data: settings } = useGetSettingsQuery();

  return (
    <div className={styles.footer}>
      <div className={styles.copyright}>Copyright SCOPEinsight B.V. 2022</div>
      <div
        className={styles.disclaimer}
        style={{ fontSize: `${settings && settings.disclaimerFont}px` }}
      >
        <span
          className={styles.warning}
          style={{ fontSize: `${settings && settings.disclaimerFont + 2}px` }}
        >
          Disclaimer:
        </span>
        <span>{settings && settings.disclaimerText}</span>
      </div>
    </div>
  );
};
