import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import './i18n';
import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import { AUTH_KEY, EXCLUDED_URLS } from './constants/constants';
import { store } from './store/store';

if (process.env.REACT_APP_ENV === 'prod') {
  console.log = () => {};
}

axios.interceptors.request.use(function (config) {
  // Check if the request URL is in the excludedEndpoints array
  if (!EXCLUDED_URLS.includes(config.url)) {
    config.headers.Authorization = `JWT ${localStorage.getItem(AUTH_KEY)}`;
  }
  return config;
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
