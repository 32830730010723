import { Checkbox, FormControlLabel } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Login } from '../../../models/login';
import { loginUser, me } from '../../../store/slices/auth';
import styles from './LoginForm.module.css';

import { IntroPage } from '../../utils/IntroPage/IntroPage';
import { CustomFormControl } from '../../utils/CustomFormControl/CustomFormControl';
import { Link, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../constants/router';
import { SubmitResponse } from '../../utils/SubmitResponse/SubmitResponse';
import { CustomButton } from '../../utils/CustomButton/CustomButton';
import { AUTH_KEY } from '../../../constants/constants';

export const LoginForm = () => {
  const [login, setLogin] = useState<Login>({ email: '', password: '' });
  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>('');
  const { t } = useTranslation('LoginForm');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.me);

  const invalidForm = () => {
    return !login.email || !login.password;
  };

  const handleAuthorization = () => {
    try {
      const allowedFrontends = JSON.parse(currentUser.allowed_frontends);
      // One-line way of checking whether the user is allowed to access portal, checking 2 conditions:
      // [User has portal in allowed front-ends AND User has valid porta\ license]
      const allowedOnPortal =
        allowedFrontends.some((x) => x.name === 'SCOPE Portal') &&
        Date.parse(currentUser.expiration_date_portal_license) > Date.now();

      if (allowedOnPortal) {
        localStorage.setItem('isLoggedIn', 'true');
        navigate(`/${RoutePath.ASSESSEES_SEARCH}`);
      }
    } catch (error) {
      localStorage.removeItem(AUTH_KEY);
      console.log(error);
      setError(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      handleAuthorization();
    }
  }, [currentUser]);

  useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        loginHandler();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [login]);

  const loginHandler = async () => {
    dispatch(loginUser(login))
      .then(() => {
        dispatch(me());
      })
      .catch((error) => {
        displayError(error);
      });
  };

  const displayError = (errorText) => {
    setError(true);
    setErrorText(errorText);
  };

  return (
    <IntroPage
      form={
        <>
          <div className={styles.title}>{t('Please login to your account to continue')}</div>
          <div className={styles.form}>
            <CustomFormControl
              type="text"
              label={t('Username')}
              value={login.email}
              onChange={(e) => setLogin((prev) => ({ ...prev, email: e }))}
            />
            <CustomFormControl
              type="password"
              label={t('Password')}
              value={login.password}
              onChange={(e) => {
                setLogin((prev) => ({ ...prev, password: e }));
              }}
            />
            <div className={styles.submit}>
              <FormControlLabel control={<Checkbox />} label={t('Remember me')} />
              <CustomButton disabled={invalidForm()} onClick={loginHandler}>
                {t('Login')}
              </CustomButton>
            </div>
          </div>
          <Link className={styles.forgotPassword} to={`/${RoutePath.RESET_PASSWORD}`}>
            {t('Forgot Your Password?')}
          </Link>
          {error && <SubmitResponse text={errorText} error={true} />}
        </>
      }
    />
  );
};

const title: React.CSSProperties = {
  marginTop: '5em',
  textAlign: 'center',
};

const form: React.CSSProperties = {
  width: '22em',
};

const submit: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: '1em',
  paddingLeft: '1em',
  paddingRight: '1em',
};

const forgotPassword: React.CSSProperties = {
  marginTop: '2em',
  marginBottom: '4em',
  textDecoration: 'unset',
};
