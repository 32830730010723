import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { AssesseeSearch } from './components/assessee/AssesseeSearch/AssesseeSearch';
import { AssesseeTable } from './components/assessee/AssesseeTable/AssesseeTable';
import { LoginForm } from './components/auth/LoginForm/LoginForm';
import { ResetPasswordForm } from './components/auth/ResetPasswordForm/ResetPasswordForm';
import { Sidebar } from './components/utils/Sidebar/Sidebar';
import { RoutePath } from './constants/router';
import FullHeight from 'react-full-height';
import { Footer } from './components/utils/Footer/Footer';
import { Toolbar } from './components/utils/Toolbar/Toolbar';
import Grid from '@mui/material/Grid';
import { SetPasswordForm } from './components/auth/SetPasswordForm/SetPasswordForm';

const App = () => {
  const location = useLocation().pathname;
  const auth =
    location === `/${RoutePath.LOGIN}` ||
    location === `/${RoutePath.RESET_PASSWORD}` ||
    location === `/${RoutePath.SET_PASSWORD}`;

  return (
    <FullHeight style={{ maxWidth: '100vw', maxHeight: '100vh' }}>
      {!auth && (
        <>
          <Toolbar />
          <Sidebar />
          <Footer />
        </>
      )}
      <Routes>
        <Route path={RoutePath.LOGIN} element={<LoginForm />} />
        <Route path={RoutePath.RESET_PASSWORD} element={<ResetPasswordForm />} />
        <Route path={RoutePath.ASSESSEES_SEARCH} element={<AssesseeSearch />} />
        <Route path={RoutePath.ASSESSEES_TABLE} element={<AssesseeTable />} />
        <Route path={RoutePath.SET_PASSWORD} element={<SetPasswordForm />} />
        <Route path="*" element={<Navigate replace to={RoutePath.LOGIN} />} />
      </Routes>
    </FullHeight>
  );
};

export default App;
