import axios from "axios"
import { Auth } from "../../models/auth"
import { Login } from "../../models/login"

const API_URL = `${process.env.REACT_APP_API_URL}/accounts/users`

export enum AuthActionType {
    LOGIN = 'login',
    RESET_PASSWORD = 'reset-password'
}

export interface AuthAction {
    type: AuthActionType,
    auth?: Auth
}

export const loginUser = (login: Login) => {
    return async (dispatch: (action: AuthAction) => void) => {
        dispatch({
            type: AuthActionType.LOGIN,
            auth: (await axios.post(`${process.env.REACT_APP_API_URL}/api-token-auth/`, login)).data
        })
    }
}

export const requestPasswordReset = (email: string) => {
    return async (dispatch: (action: AuthAction) => void) => {
        await axios.post(`${API_URL}/request_password_reset/`, { email })
        dispatch({
            type: AuthActionType.RESET_PASSWORD
        })
    }
}