import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Assessee } from '../../models/assessee';
import { PAGE_SIZE_TABLE, PAGE_SIZE_CARD, TOOL_TYPES } from '../../constants/constants';

const API_URL = `${process.env.REACT_APP_API_URL}/customers/producing_organizations`;

interface AssesseeState {
  assessees: Assessee[];
  assessee: Assessee;
  pagesCount: number;
  downloading: boolean;
  dataCount: number;
  loading: boolean;
}

const initialState: AssesseeState = {
  assessees: [],
  assessee: null,
  pagesCount: null,
  downloading: false,
  dataCount: null,
  loading: false,
};

const downloadToSystem = (data, name) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', name);
  link.click();
  window.URL.revokeObjectURL(url);
};

//TODO: create some front-end spice to hide long get request timers

export const searchAssessees = createAsyncThunk(
  'assessee/searchAssessees',
  async (params: {
    search: string;
    page: number;
    viewType: string | null;
    pageSize?: number | null;
  }) => {
    // const currentPageSize = (params.viewType === 'module' ? PAGE_SIZE_CARD : PAGE_SIZE_TABLE[0]);
    const currentPageSize = params.pageSize ? params.pageSize : PAGE_SIZE_TABLE[0];
    const data = (
      await axios.get(
        `${API_URL}/?fields=customer&page_size=${currentPageSize}&page=${params.page}&status=has_final&portal=true&${params.search}`
      )
    ).data;
    return {
      assessees: data,
      pagesCount: Math.ceil(data.count / currentPageSize),
      dataCount: data.count,
    };
  }
);

export const exportToExcel = createAsyncThunk(
  'assessee/exportToExcel',
  async (params: { search: string; selectedIds: any }) => {
    let selectedIdParam = '';
    params.selectedIds.forEach((x) => {
      selectedIdParam += `&ids=${x}`;
    });
    const data = (
      await axios.get(
        `${API_URL}/export_orgs/?${params.search}&status=has_final&portal=true${selectedIdParam}`,
        {
          responseType: 'blob',
        }
      )
    ).data;
    downloadToSystem(data, `SCOPE Portal_${Date.now()}.xlsx`);
  }
);

export const exportToPdf = createAsyncThunk(
  'assessee/exportToPdf',
  async (params: { search: string; selectedIds: any }) => {
    let selectedIdParam = '';
    params.selectedIds.forEach((x) => {
      selectedIdParam += `&ids=${x}`;
    });
    const data = (
      await axios.get(
        `${API_URL}/export_orgs_pdf/?${params.search}&status=has_final&portal=true${selectedIdParam}`,
        {
          responseType: 'blob',
        }
      )
    ).data;
    downloadToSystem(data, `SCOPE Portal_Company_Reports_${Date.now()}.zip`);
  }
);

export const assesseeSlice = createSlice({
  name: 'assessee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get assessees
    builder.addCase(searchAssessees.pending, (state, action) => {
      console.log('fetching assessees');
      return { ...state, loading: true };
    });
    builder.addCase(searchAssessees.rejected, (state, action) => {
      console.log('failed to fetch assessees');
      state = { ...state, loading: false };
      throw action.error;
    });
    builder.addCase(searchAssessees.fulfilled, (state, action) => {
      console.log('successfully retrieved assessees');
      return {
        ...state,
        assessees: action.payload.assessees.results,
        pagesCount: action.payload.pagesCount,
        dataCount: action.payload.dataCount,
        loading: false,
      };
    });

    // export assessees to excel sheet
    builder.addCase(exportToExcel.pending, (state, action) => {
      console.log('preparing download');
      return { ...state, downloading: true };
    });
    builder.addCase(exportToExcel.rejected, (state, action) => {
      console.log('download failed');
      state = { ...state, downloading: false };
      throw action.error;
    });
    builder.addCase(exportToExcel.fulfilled, (state, action) => {
      console.log('download successful');
      return { ...state, downloading: false };
    });

    // export assessees to pdf report
    builder.addCase(exportToPdf.pending, (state, action) => {
      console.log('preparing pdf download');
      return { ...state, downloading: true };
    });
    builder.addCase(exportToPdf.rejected, (state, action) => {
      console.log('download failed');
      return { ...state, downloading: false, error: action.error };
    });
    builder.addCase(exportToPdf.fulfilled, (state, action) => {
      console.log('download pdf successful');
      return { ...state, downloading: false };
    });
  },
});
