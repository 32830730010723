import React, { useEffect } from 'react';
import { TOOLBAR_COLOR } from '../../../constants/constants';
import { Icon } from '@mui/material';
import { HelpItem } from '../score-help';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { closeOtherHelps } from '../../../store/slices/score-help';
import { RootReducer } from '../../../store/root-reducer';
import { FILTER_FIELD, updateFilters } from '../../../store/slices/filter';
import styles from './RangeFilter.module.css';

export const RangeFilter = (props: {
  title: 'total_score' | 'market_score' | 'financial_score';
  data: HelpItem[];
  itemTitle: string;
}) => {
  let title = '';
  const numbers = [1, 2, 3, 4, 5];
  const dispatch = useAppDispatch();
  const selectedNumber = useAppSelector((state) => state.filters[props.itemTitle]);
  const helpOpened = useAppSelector((state) => state.scoreHelp.currentTitle) === props.title;
  const { t } = useTranslation('RangeFilter');

  const TITLE_MAPPING = {
    totalInfo: t('Total SCOPE Score'),
    marketInfo: t('SCOPE Score on Markets'),
    financialInfo: t('SCOPE Score on Financial Management'),
  };

  useEffect(() => {
    title = props.title.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
    title = title.replace('info', 'score');
  });

  const selectNumberHandler = (number: number) => {
    number = selectedNumber === number ? null : number;
    dispatch(updateFilters({ field: props.itemTitle as FILTER_FIELD, value: number }));
  };

  const toggleHelpHandler = () => {
    dispatch(closeOtherHelps(helpOpened ? undefined : props.title));
  };

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.header}>
          <span className={styles.title}>{TITLE_MAPPING[props.title]}</span>
          <Icon
            onClick={toggleHelpHandler}
            className={[styles.infoIcon, helpOpened ? styles.selectedIconInfo : ''].join(' ')}
            fontSize="small"
          >
            info
          </Icon>
        </div>
        {numbers.map((number) => (
          <span
            key={number}
            onClick={selectNumberHandler.bind(null, number)}
            className={[
              styles.numberContainer,
              selectedNumber === number ? styles.selectedNumberContainer : '',
            ].join(' ')}
          >
            {number === 5 && <span style={{ color: 'white' }}>`</span>}
            {number}
            {number === 5 && <span style={{ color: 'white' }}>-</span>}
            {number < 5 && '+'}
          </span>
        ))}
      </div>

      {helpOpened && (
        <div className={styles.help}>
          <div className={styles.helpTitle}>{TITLE_MAPPING[props.title]}</div>
          <textarea
            className={styles.infoTextArea}
            value={props.data.toString()}
            readOnly
          ></textarea>
          {/* {props.data.map((item) => (
            <div
              key={item.text}
              className={[
                item.marginTop && styles.marginTop,
                item.highlight && styles.highlightText,
              ].join(' ')}
            >
              {item.text}
            </div>
          ))} */}
        </div>
      )}
    </div>
  );
};
