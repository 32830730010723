import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterState {
    country: string[];
    legal_status: string[];
    sector: string[];
    total_score: number;
    market_score: number;
    financial_score: number;
    net_profit: number;
    total_revenue: number;
    name: string;
    years_in_operation: string;
}

const initialState: FilterState = {
    country: [],
    legal_status: [],
    sector: [],
    total_score: null,
    market_score: null,
    financial_score: null,
    net_profit: null,
    total_revenue: null,
    name: '',
    years_in_operation: '',
}

export interface PayloadType {
    field: FILTER_FIELD
    value: string[] | number
}

export type FILTER_FIELD =
    'country' |
    'legal_status' |
    'sector' |
    'total_score' |
    'market_score' |
    'financial_score' |
    'net_profit' |
    'total_revenue' |
    'name' |
    'years_in_operation';


export const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        updateFilters: (state, action: PayloadAction<PayloadType>) => {
            console.log('updating filters', action.payload.field, ':', action.payload.value)
            return { ...state, [action.payload.field]: action.payload.value }
        },
        resetFilters: () => {
            return initialState;
        }
    }
})

export const { updateFilters, resetFilters } = filterSlice.actions;