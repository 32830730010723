import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../constants/router';
import styles from './Sidebar.module.css';

export const Sidebar = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.sidebar}>
      <div className={styles.logo} onClick={() => navigate(RoutePath.ASSESSEES_SEARCH)}>
        <img src="images/logo_white.svg" className={styles.image} />
      </div>
    </div>
  );
};
