import { useTranslation } from 'react-i18next';
import styles from './IntroPage.module.css';

const Better = (props: { children: string }) => {
  return <span className={styles.better}>{props.children}</span>;
};

export const IntroPage = (props: { form: JSX.Element }) => {
  const { t } = useTranslation('IntroPage');

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <div>
            <img src="images/logo.png" />
          </div>
          <div className={styles.details}>
            <Better>Better</Better> solutions, <Better>better</Better> businesses,{' '}
            <Better>better</Better> lives
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.form}>
          <div className={styles.title}>{t('Welcome to SCOPE Portal')}</div>
          {props.form}
        </div>
      </div>
      <div className={styles.footer}>
        <small>
          &copy; SCOPE<em>insight</em> B.V.<span>{t('All rights reserved')}</span>
        </small>
      </div>
    </div>
  );
};

const container: React.CSSProperties = {
  height: '100%',
  background: 'linear-gradient(180deg, rgba(145, 213, 221, 0.15) 70%, rgba(40, 37, 97, 0.15) 100%)',
};

const main: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
};

const logo: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flex: 1,
};

const details: React.CSSProperties = {
  marginTop: '1em',
};

const better: React.CSSProperties = {
  color: '#00abbf',
};

const divider: React.CSSProperties = {
  border: '1px solid #ccc',
  height: '80%',
};

const form: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '1em',
  flex: 1,
};

const title: React.CSSProperties = {
  fontSize: '2em',
  fontFamily: "'Montserrat', sans-serif",
};

const paragraph: React.CSSProperties = {
  color: '#00af8c',
  fontFamily: "'Montserrat', sans-serif",
  fontStyle: 'italic',
};

const footer: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  marginBottom: '1em',
  textAlign: 'center',
};
